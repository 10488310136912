import React, { Component } from "react";
import Slider from "react-slick";
import MarkdownRenderer from "react-markdown-renderer";
import Layout from "../components/layout";
import { Button, Breadcrumbs } from "../components/styleguide";

class About extends Component {
  state = {};

  renderOfferts = (from, to) => {
    const { offerts, data } = this.props.pageContext;
    const { first_image, second_image, third_image } = data;
    const imageData = [
      {
        type: "background",
        order: 9,
        image: first_image ? `url('${first_image}')` : `url('${require("../images/about/grid-image3.png")}')`
      },
      {
        type: "background",
        order: 12,
        image: second_image ? `url('${second_image}')` : `url('${require("../images/about/grid-image.png")}')`
      },
      {
        type: "background",
        order: 13,
        image: third_image ? `url('${third_image}')` : `url('${require("../images/about/grid-image2.png")}')`
      }
    ];
    const orderedOfferts = offerts.concat(imageData).sort((prev, next) => prev.order - next.order);
    return (
      <div className="grid-row">
        {orderedOfferts.slice(from, to).map(({ id, title, description, image, type }) =>
          type && type === "background" ? (
            <div className="grid-item bg" key={id}>
              <div
                className="lazyload bg-item"
                style={{
                  backgroundImage: image
                }}
              />
            </div>
          ) : (
            <div className={`grid-item`} key={id}>
              <div className="item-content">
                <img className="lazyload" data-src={image} alt={title.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, "")} />
                {title && <MarkdownRenderer markdown={title} />}
                {description && <MarkdownRenderer markdown={description} />}
              </div>
            </div>
          )
        )}
      </div>
    );
  };
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 2500,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };
    const { data, common, breadcrumbs, productsPath } = this.props.pageContext;

    return (
      <Layout data={common} title={data.seoTitle} description={data.seoDescription} hrefLang={data.hrefLang}>
        <div className="main-container">
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
          <section className="about-info">
            <div
              className="lazyload about-image"
              style={{
                backgroundImage: `url('${require("../images/about/about-bg.png")}')`
              }}
            />
            <header className="section-header">
              <MarkdownRenderer markdown={data.title} />
            </header>
            <p>{data.description}</p>
            <div className="additional-info">
              <MarkdownRenderer markdown={data.section_producents_title} />
              <p>{data.section_producents_desc}</p>
            </div>
            <Slider {...settings} className="producents-slider">
              {data.producents.concat(data.producents).map(({ name, logo }, index) => (
                <div className="single-item" key={index}>
                  <img className="lazyload" data-src={logo} alt={name} />
                </div>
              ))}
            </Slider>
          </section>
        </div>
        <section className="about-offer">
          <div className="main-container">
            <header className="section-header">
              <MarkdownRenderer markdown={data.section_offerts_title} />
            </header>
          </div>
          <div className="rotated-grid">
            {this.renderOfferts(0, 1)}
            {this.renderOfferts(1, 4)}
            {this.renderOfferts(4, 9)}
            {this.renderOfferts(9, 12)}
            {this.renderOfferts(12, 13)}
          </div>
          <div className="grid-button">
            <Button type="link" content={common.translations[0].go_to_products} fill href={productsPath} />
          </div>
        </section>
      </Layout>
    );
  }
}

export default About;
